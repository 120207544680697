import React from "react"
import Container from "../components/container"
import FinalCTA from "../components/final-cta"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MasterClassEmailSignup from "../components/master-class-email-signup"

const Contact = () => {
  return (
    <Layout>
      <Container>
        <div className="text-center pt-24 pb-24" style={{ zIndex: 30 }}>
          <MasterClassEmailSignup />
        </div>
      </Container>
      <FinalCTA />
    </Layout>
  )
}

export const Head = () => <Seo title={"Contact"} />

export default Contact
